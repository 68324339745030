import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    info:{
      'name':'Loading'
    },
    isActiveLeftSidebar:false,
    isActiveRightSidebar:false,
    routes :[
      {
          'label':'Home',
          'route':'/',
          'icon':'home',
          'flag':true,
      },
      {
          'label':'Contact',
          'route':'/contact',
          'icon':'handshake',
          'flag':false,
      },
      {
          'label':'Background',
          'route':'/background',
          'icon':'user-graduate',
          'flag':false,
      },
      {
          'label':'Portfolio',
          'route':'/portfolio',
          'icon':'laptop-code',
          'flag':false,
      }
    
  ]
  },
  mutations: {
    updateRouteFlag(state,payload){
      state.routes[payload].flag = true

    },
    updateInfo(state,payload){
      state.info = payload
    },
    updateisActiveLeftSidebar(state,payload){
      state.isActiveLeftSidebar = payload
    },
    updateisActiveRightSidebar(state,payload){
      state.isActiveRightSidebar = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
