<template>


    
  <div id="Contacts" class="overflow-auto h-full text-2xl font-bold  text-white">
        <div class="h-80" :style="{ backgroundImage: 'url('+coverImg+')' }">
            <div class="h-full bg-gradient-to-t from-main-color px-3 md:px-8 md:pt-10 pt-4 ">
              <Contact/>
            </div>
        </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Contact from '@/components/Contact.vue'

export default {
  name: 'ContactView',
  components: {
    Contact
  },
  data(){
    return {
      coverImg: require('@/assets/cover.png'),
    }
  },
  created(){
    this.$root.$refs.appInstance.deactivateSidebars()
  }
}
</script>

<style>

#Contacts {
 
  overflow-y: scroll; /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
#Contacts::-webkit-scrollbar{
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#Contacts{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>