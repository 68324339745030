<template>
  <div id="Portfolio" class="overflow-auto h-full flex-1 text-2xl font-bold  text-white">
        <div class="h-80" :style="{ backgroundImage: 'url('+coverImg+')' }">
            <div class="h-full bg-gradient-to-t from-main-color px-3 md:px-8 md:pt-10 pt-4 "> 
              <Portfolio/>
            </div>
        </div>
  </div>

</template>

<script>

import Portfolio from '@/components/Portfolio.vue'
export default {
  name: 'PortfolioView',
  components: {
    Portfolio,
  },
  data(){
    return {
      coverImg: require('@/assets/cover.png'),
    }
  },
  created(){
    this.$root.$refs.appInstance.deactivateSidebars()
  }
}
</script>

<style>

#Portfolio {
 
  overflow-y: scroll; /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
#Portfolio::-webkit-scrollbar{
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#Portfolio{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>