<template>
  <div id="History" class="overflow-auto h-full flex-1 text-2xl font-bold  text-white">
        <div class="h-80" :style="{ backgroundImage: 'url('+coverImg+')' }">
            <div class="h-full bg-gradient-to-t from-main-color px-3 md:px-8 md:pt-10 pt-4 ">
              <History/>
            </div>
        </div>
  </div>
</template>

<script>
// @ is an alias to /src
import History from '@/components/History.vue'
// import eventBus from '../main'
export default {
  name: 'HistoryView',
  components: {
    History,
  },
  data(){
    return {
      coverImg: require('@/assets/cover.png'),
    }
  },
  created(){
    this.$root.$refs.appInstance.deactivateSidebars()
  }

}
</script>

<style>

#History {
 
  overflow-y: scroll; /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
#History::-webkit-scrollbar{
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#History{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>