<template>
  <div class=" bg-grey-700 lg:flex font-sans lg:mx-4 h-full " >
    <div id="Home" class=" overflow-auto h-full text-2xl font-bold  text-white">
          <div class="h-96 lg:h-80" :style="{ backgroundImage: 'url('+coverImg+')' }">
              <div class="h-full bg-gradient-to-t from-main-color px-3 md:px-8 md:pt-10 pt-4 ">            
                <Main :bioArray= "info.bio"
                      :Achievements= "info.Achievements"
                      :Experties = "info.Experties" 
                      :Recommendations = "info.Recommendations"
                      :Email = "info.Contact.Email"
                      :Phone = "info.Contact.Phone"
                      :Reviews = "info.Reviews"
                />
              </div>
          </div>
    </div>
  </div>
</template>

<script>
import Main from '@/components/Main.vue'
export default {
  name: 'Home',
  components: {
    Main,
  },
  
  data(){
    return {
      coverImg: require('@/assets/cover.png'),  
    }
  },
  created(){
    this.info=this.$store.state.info
    this.$root.$refs.appInstance.deactivateSidebars()
  },
}
</script>

<style>

#Home {
 
  overflow-y: scroll; /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
#Home::-webkit-scrollbar{
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#Home{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>