<template>

  
    <div>
      <div class="sticky bg-opacity-95 bg-light-gray top-0 z-20">

        <div class="lg:hidden flex justify-end w-full" >
        <button  @click="sidebarToggle" class=" pt-4 px-4 bg-light-gray focus:outline-none">
          <font-awesome-icon icon="times" size="1x"/>
        </button>
        </div>

      <div class="lg:pt-4 pt-2 text-center space-y-2 text-xs filter drop-shadow-sm">

        <img class="mx-auto h-1/4 w-1/3 rounded-full" :src="userInfo.img"/>
        <div class="px-2 pb-3">
        <span class="flex h-3 w-3">
          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75"></span>
          <span class="relative inline-flex rounded-full h-3 w-3 bg-purple-500"></span>
        </span>
        
        <h1 class="font-bold text-sm">{{userInfo.name}}</h1>
        <div class="text-gray-500 inline flex-row"  v-for="(b,index) in userInfo.bio" :key="index">{{b}} <span v-if="index%2==0">| </span> </div>
        </div>

      </div >
      </div>


        <nav class="p-5">

          <div class="flex flex-col text-justify text-xs py-1 font-medium" >
           <div> <div class=" inline float-left">Residence </div><div class="inline text-gray-500 py-1 font-normal float-right" >{{userInfo.Contact.Residence}}</div></div>
           <div> <div class=" inline float-left">City </div><div class="text-gray-500  font-normal py-1 float-right">{{userInfo.Contact.City}}</div></div>
           
           
          </div>

          <hr class="my-5 text-gray-500">

          <div >
            <div class="font-bold ">Languages</div>
              <div class=" flex flex-row justify-center space-x-6">
              <div  class="flex flex-col w-20 " v-for="(score,lang,index) in userInfo.Languages" :key="index">
                
                  <div class="z-0">
                    <RadialBar :score="score"/>
                  </div>

                  <div class="font-bold text-center ">
                    {{lang}}
                  </div> 

                </div>
            </div>
          </div>

          <hr class="my-5 text-gray-500">
        
        
          <div class="font-bold pb-4 ">Skills and Competencies</div>

          <div>
          <div class="flex flex-col  py-2 " v-for="(score,skill,index) in userInfo.Skills" :key="index">

            <div><div class="float-left">{{skill}}</div><div class="float-right">{{score}}%</div></div>

            <div>
             <ProgressBar :barValue="score"/>
             </div>

          </div>
          
          </div>

          <hr class="my-5 text-gray-500">
          <div id="Tools" class="font-bold ">Tools</div>
          <div class="flex flex-wrap py-2 ">
            <div class="bg-dark-yellow  mx-1 my-1 p-3 text-xs rounded-3xl text-white font-light" v-for="(tool,index) in userInfo.Tools" :key="index">

                {{tool}}
            </div>
          </div>

          <hr class="my-5 text-gray-500">

          <div class="flex flex-col " >
          <div class=" pt-2" >Email  </div><div class=" text-gray-500 " >{{userInfo.Contact.Email}}</div>
          <div class=" pt-2" >Phone </div><div class=" text-gray-500 ">{{userInfo.Contact.Phone}}</div>
          </div>

          <hr class="my-5 text-gray-500">

          <a :href="userInfo.Resume" target="_blank">
            
            <div class="flex text-gray-500 text-xs font-extrabold space-x-2">
              <div>DOWNLOAD CV </div>  
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4s" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                </svg>
              </div> 
            </div></a> 

          
        </nav>

        <div class="sticky bottom-0 flex justify-center p-2 space-x-6 bg-dark-gray text-white">
          <a :href="userInfo.Socials.linkedin" target="_blank" class="text-gray"><font-awesome-icon  :icon="['fab', 'linkedin']" :style="{ color: 'white',height:'20px',width:'20px' }"/></a>
          <a :href="userInfo.Socials.github" target="_blank"><font-awesome-icon :icon="['fab', 'github']" :style="{ color: 'white',height:'20px',width:'20px' }"/></a>
          <a :href="userInfo.Socials.twitter" target="_blank"><font-awesome-icon :icon="['fab', 'twitter']" :style="{ color: 'white',height:'20px',width:'20px' }"/></a>
          <a :class="classA" :href="userInfo.Socials.medium" target="_blank"><font-awesome-icon :icon="['fab', 'medium']" :style="{ color: 'white',height:'20px',width:'20px' }"/></a>
          <a :href="userInfo.Socials.youtube" target="_blank"><font-awesome-icon :icon="['fab', 'youtube']" :style="{ color: 'white',height:'20px',width:'20px' }"/></a>

        </div>
    </div>


    

</template>

<script>
import RadialBar from '@/components/RadialBar.vue'
import ProgressBar from '@/components/ProgressBar.vue'

export default {
  name: 'SideBar',
  components: {
    RadialBar,
    ProgressBar
    
  },
  created(){
    this.userInfo=this.$store.state.info

  },
  methods:{
    sidebarToggle(){
      this.$root.$refs.appInstance.activateLeftSidebar()
    }
  }
}
</script>
