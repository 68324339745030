<template>
  <div>
    <div class="text-base">Contact Information</div>

      <div class="grid grid-flow-row md:grid-cols-2 grid-rows-1 gap-4  w-full my-6 text-base font-normal">
            <div class="flex flex-col text-justify text-xs font-medium bg-gray p-8" >
                <div class="py-2"> <div class=" inline float-left">Country: </div><div class="inline text-gray-500 py-1 font-normal float-right" >{{info.Contact.Residence}}</div></div>
                <div class="py-2"> <div class=" inline float-left">City: </div><div class="text-gray-500  font-normal py-1 float-right">{{info.Contact.City}}</div></div>
                <div class="py-2"> <div class=" inline float-left">Company: </div><div class="text-gray-500 font-normal  float-right"><a target="_blank" :href="info.Experience[0].site">{{info.Experience[0].company}}</a></div></div>
            </div>

            <div class="flex flex-col text-justify text-xs font-medium bg-gray p-8" >
                <div class="py-2"> <div class=" inline float-left">Email: </div><div class="inline text-gray-500 py-1 font-normal float-right" >{{info.Contact.Email}}</div></div>
                <div class="py-2"> <div class=" inline float-left">LinkedIn: </div><div class="text-gray-500  font-normal py-1 float-right"><a target="_blank" :href="info.Socials.linkedin">{{info.Socials.linkedin_id}}</a></div></div>
                <div class="py-2"> <div class=" inline float-left">Phone: </div><div class="text-gray-500 font-normal  float-right">{{info.Contact.Phone}}</div></div>
            </div>
      </div>

      <div class="sticky bottom-0 flex justify-center p-2 mb-4 space-x-6 md:space-x-10 md:space-x-12 bg-gray text-white">
          <a :class="classA" :href="getMailTo()" ><font-awesome-icon  icon="envelope" :style="{ color: 'white',height:'30px',width:'30px' }"/></a>
          <a :class="classA" :href="info.Socials.linkedin" target="_blank"><font-awesome-icon  :icon="['fab', 'linkedin']" :style="{ color: 'white',height:'30px',width:'30px' }"/></a>
          <a :class="classA" :href="info.Socials.github" target="_blank"><font-awesome-icon :icon="['fab', 'github']" :style="{ color: 'white',height:'30px',width:'30px' }"/></a>
          <a :class="classA" :href="info.Socials.twitter" target="_blank"><font-awesome-icon :icon="['fab', 'twitter']" :style="{ color: 'white',height:'30px',width:'30px' }"/></a>
          <a :class="classA" :href="info.Socials.medium" target="_blank"><font-awesome-icon :icon="['fab', 'medium']" :style="{ color: 'white',height:'30px',width:'30px' }"/></a>
          <a :class="classA" :href="info.Socials.youtube" target="_blank"><font-awesome-icon :icon="['fab', 'youtube']" :style="{ color: 'white',height:'30px',width:'30px' }"/></a>

        </div>

    <div class="text-base pb-6">Get In Touch</div>

    <div class="py-4 px-8 bg-gray text-sm">
        <div >
          
           <div class="flex w-full"><div class="p-4 bg-main-color"><font-awesome-icon icon="user" transform="shrink-2"/></div><div class="w-full"><input type="text" id="name" name="fullname" placeholder="Name" class="w-full bg-main-color p-4"></div></div><br>
          
          <div class="flex w-full"><div class="p-4 bg-main-color text-white">@</div><input type="email" id="emailaddr" name="email" placeholder="Email" class=" w-full bg-main-color p-4"></div><br><br>
          
          <div class="flex p-0 m-0 w-full"><div class="p-4  bg-main-color"><font-awesome-icon icon="envelope" transform="shrink-2"/></div><div class="w-full h-full bg-main-color"><textarea placeholder="Message" id="textArea" name="textarea" rows="6" cols="50" class="bg-main-color text-white w-full p-4">
            
        </textarea></div></div>
          <div @click="showAlert" class="my-4">
              <button class="px-8 py-2  bg-yellow text-main-color text-base font-medium" style="font-family: Monospace"> SEND MESSAGE </button>
          </div>

        </div>
    </div>

      <div class="mt-4 ">
              <div class="flex justify-between text-xs font-normal text-gray-500 py-4 px-2 md:px-4 w-full bg-gray">
                  <div><font-awesome-icon icon="copyright" transform="shrink-2"/> 2022 All Rights Reserved.</div>
                  <div class="hidden md:flex">Phone: {{info.Contact.Phone}}</div>
                  <div class="flex"><div class="mr-1"><font-awesome-icon  icon="envelope" :style="{height:'12px',width:'12px' }"/></div> <div>{{info.Contact.Email}}</div></div>
              </div>
      </div>

  </div>
</template>

<script>
export default {

  data(){
    return {
      classA: 'transition duration-150 ease-in-out bg-blue-600 hover:bg-red-600 transform hover:-translate-y-1 hover:scale-110'
    }
  },
  created(){
    this.info=this.$store.state.info
    

  },
  methods: {
    showAlert() {
      // Use sweetalert2
      this.$swal({'title': "<h5 style='color:#FFC107'>"+ 'Cooking...' +"</h5>",
      text: 'This feature is in development at this time.',
      confirmButtonText:"<div style='color:#161A23'>"+  'OK'+"</div>"});
    },
    getMailTo(){
      return 'mailto:'+this.info.Contact.Email
    }
  },
}
</script>