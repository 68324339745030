<template>
    <div  >
        
        <button @click="activateRightSidebar()" class="flex justify-center p-4 bg-light-gray focus:outline-none w-full">
            
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            
        </button>

        <!-- <div>
        <div class=" text-sm font-bold my-24 transform  rotate-90">
            
            <router-link to="/">Home</router-link>
         
        </div>

        <div class=" text-sm font-bold my-24 transform  rotate-90">
            
            <router-link to="/contact">Contacts</router-link>
        </div>

        <div class=" text-sm font-bold my-24 transform  rotate-90">
            
            <router-link to="/history">History</router-link> 
        </div>

        <div class=" text-sm font-bold transform  rotate-90">
            
            <router-link to="/portfolio">Portfolio</router-link> 
        </div>

        </div> -->

    </div>

</template>

<script>
export default {
    methods:{
        activateRightSidebar: function(){
            this.$store.commit("updateisActiveRightSidebar", !this.$store.state.isActiveRightSidebar);
        }
    }
}
</script>
