<template>


    
    <div id="Main" class="text-sm overflow-x-hidden">

        <div class="bg-silver bg-opacity-20 p-10  lg:h-auto lg:p-0 filter drop-shadow-2xl"> 

            <div class="flex">
            <div class="md:flex-col filter drop-shadow-none lg:pl-10 lg:py-5">
            
                <div class="font-sans text-4xl md:text-5xl font-extrabold  tracking-wider text-white leading-snug  drop-shadow-2xl">Hey! Welcome to my Devfolio!</div>
                
                <div class=" py-4 pb-4 text-sm h-20 lg:h-auto" style="font-family: Monospace">

                    <span> 
                    {{lessThan}}<span class="text-yellow">code</span>{{greaterThan}}
                    <typewriter
                    
                        :speed="100"
                        :full-erase="true"
                        :interval="300"
                        :words="bio">
                        I am
                    </typewriter>
                    {{lessThan}}/<span class="text-yellow">code</span>{{greaterThan}}

                    </span>
                </div>

                <div>
                    <button @click="scroll()" class="px-8 py-2  bg-yellow text-main-color text-base font-medium" style="font-family: Monospace"> Explore </button>
                </div>
            </div>
            <div class="hidden lg:flex items-end justify-center  w-2/5">
            <img src="@/assets/animoji.png" class=" lg:h-68 xl:h-56">
            </div>
            </div>
            
        </div>
            



        <div>

            <div class="grid grid-cols-2 md:flex">  
                <div class="inline space-x-1   text-sm px-2 md:px-8 py-2 " v-for="(score,value,index) in Achievements" :key="index">
                    <div><span class="tracking-wide text-yellow text-base md:text-xl font-bold">{{score}} </span><span class=" tracking-wide text-xs font-semibold">{{value}}</span></div>
                </div>
            </div>

        </div>

        
        <div class="px-2 md:px-8 py-4 text-lg">My Expertise</div>
        <div class="grid justify items-center grid-flow-row md:grid-cols-2 lg:grid-cols-3 grid-rows-auto gap-4 px-2 md:px-8 ">
        <div class="h-full" v-for="(desc,title,index) in Experties" :key="index">

            <div class="h-full bg-gray space-y-2 p-8"><div class=" text-white">{{title}} </div><div class="text-sm text-gray-500 font-normal "> {{desc}}</div></div> 
        </div>
        </div>

        <div class="px-2 md:px-8 pt-8 text-lg">Recommendations</div>
        <div class="grid h-full mt-5 justify-items-center grid-flow-row md:grid-cols-2 grid-rows-auto gap-x-4 gap-y-12 px-2 md:px-8 pb-8">
        <div class="bg-main-color h-full relative" v-for="(recommendation,index) in Recommendations" :key="index">
            
            <div class="bg-gray p-8 h-full relative">
                <img class="absolute z-10 right-10 -top-5 border-yellow w-16 h-16  border-2 rounded-full m-0" :src="recommendation.img">
                <div class=" text-white"><a :href="recommendation.profile" target="_blank">{{recommendation.name}}</a></div>
                <div class ="text-xs text-gray-500 font-normal italic mt-1 ">{{recommendation.position}}</div>
                <div class="text-sm mt-2 text-gray-500 font-normal "> {{recommendation.recommendation}}</div>
            </div> 
        </div>
        </div>

        <div class="px-2 md:px-8 py-4 pt-8 w-full text-lg">Clients Reviews</div>
        <div id="Reviews" class="overflow-auto w-full grid  justify-items-center grid-flow-col gap-4 px-2 md:px-8">
        <div class="p-0 m-0" v-for="(review,index) in Reviews" :key="index">
            <div class="flex flex-col justify-between bg-gray w-80 md:w-96 h-full  p-4 md:p-8">
                <div>
                <div class="flex justify-between w-full">
                    <div class=" text-white">{{review.name}} </div>
                    <div class="pt-2 text-xs opacity-50"><a :href="$store.state.info.Socials.fiverr" target="_blank">Fiverr</a></div>
                </div>
                
                <div class ="text-xs text-gray-500 font-normal italic ">
                    <font-awesome-icon icon="map-marker-alt" transform="shrink-2"/> {{review.location}}
                </div>
                </div>
                <div class="text-sm mt-2 text-gray-500 font-normal "> {{review.review}}</div>

                <div  class=" bg-main-color w-6/12 md:w-5/12 text-xs text-yellow rounded-full p-2 mt-4 "> 
                <div class="inline px-1 " v-for="(star,index) in review.ratings" :key="index">
                    <font-awesome-icon icon="star" transform="shrink-2" />
                </div>
                 
                </div>

            </div> 

        </div>
        </div>


        <div class="mt-4 ">

            <div class="flex justify-between text-xs font-normal text-gray-500 py-4 px-2 md:px-4 w-full bg-gray">
                <div><font-awesome-icon icon="copyright" transform="shrink-2"/> 2022 All Rights Reserved.</div>
                <div class="hidden md:flex">Phone: {{Phone}}</div>
                <div class="flex"><div class="mr-1"><font-awesome-icon  icon="envelope" :style="{height:'12px',width:'12px' }"/></div> <div>{{Email}}</div></div>
            </div>
        </div> 


        
    </div>
</template>
 
<script>
export default {

    props:{
        bioArray:{
            type:Array
            },
        Achievements: Object,
        Experties:Object,
        Recommendations:Array,
        Email:String,
        Phone:String,
        Reviews:Array,
    },
    data(){
        return {
            tempArray:[],
            coverImg: require('@/assets/cover.png'),
            lessThan: '<',
            greaterThan: '>',
            //wordsArray:['Google DSC Lead', 'AWS Community Builder','Full Stack Developer','Data Enthusiast','Open-Source Contributor']
        }
    },
    computed:{
        bio(){
            return (
                this.bioArray.map(bio=> bio+'!')
            )
        }
    },
   
    methods:{
    scroll() { 
      if (window.innerWidth > 1000){ 
        document.getElementById('Tools').scrollIntoView({
        behavior: "smooth"
      })
      }else{
           this.$root.$refs.appInstance.activateLeftSidebar()
       }
    },
  }
    
    
    
}
</script>

<style>

#Main {
 
  overflow-y: scroll; /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
#Main::-webkit-scrollbar{
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#Main{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* width */
#Reviews::-webkit-scrollbar {
  width: 20%;
  margin-top: 10px;
  height: 6px;
}

/* Track */
#Reviews::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
#Reviews::-webkit-scrollbar-thumb {
  background: #FFC107; 
  border-radius: 10px;
}

/* Handle on hover */
#Reviews::-webkit-scrollbar-thumb:hover {
  background: #FFC107; 
}
</style>