<template>
    <div>
    <div class="grid md:grid-cols-2 md:divide-x-4 md:divide-yellow">
        <div class="order-2 md:order-1">
            <div class="mt-10 md:mt-0  text-md">Education</div>
            <div class="bg-gray px-8 py-10 mt-4 md:mr-12" v-for="(Ed,index) in info.Education" :key="index"> 
               
                <div class="flex justify-between">
                    <div class="flex-initial">{{Ed.name}}</div>
                    <div class="flex justify-items-center justify-center bg-main-color ml-2 w-32 lg:w-28 h-10 text-xs rounded-full py-3 font-normal opacity-50 text-center"> {{Ed.period}} </div>
                </div>
                <div class ="text-xs text-gray-500 font-normal italic mt-1 ">{{Ed.degree}}, {{Ed.status}}</div>
                <div class="text-gray-500 text-sm font-normal mt-4 ">{{Ed.desc}}</div>
  
            </div>

        </div>

        <div class="order-1 md:order-2" >
            <div class="md:ml-12">
            
            <div class="text-md md:pt-0 pt-4">Experience</div>
                <div class="bg-gray px-8 py-10 mt-4 " v-for="(Ex,index) in info.Experience" :key="index"> 
                    
                    <div class="flex-initial">{{Ex.company}}</div>
                    <div class ="text-sm text-gray-500 font-normal italic mt-1 ">{{Ex.position}}</div>
                    <div class="text-gray-500 text-xs opacity-50 font-normal italic"><a :href=Ex.site target="_blank">{{Ex.site}}</a></div>
                    <div class="text-gray-500 text-sm font-normal mt-4 text-justify">{{Ex.desc}}</div>
                    <div class="flex justify-between bg-main-color w-full h-auto text-xs rounded-full py-3 px-6 mt-4 font-normal opacity-50">
                        <div>{{Ex.period}}</div> <div> {{Ex.location}}</div>
                        
                    </div>
                </div>
            </div>

        </div>

        
    </div>
        <div class="mt-4 ">
                <div class="flex justify-between text-xs font-normal text-gray-500 py-4 px-2 md:px-4 w-full bg-gray">
                    <div><font-awesome-icon icon="copyright" transform="shrink-2"/> 2022 All Rights Reserved.</div>
                    <div class="hidden md:flex">Phone: {{info.Contact.Phone}}</div>
                    <div class="flex"><div class="mr-1"><font-awesome-icon  icon="envelope" :style="{height:'12px',width:'12px' }"/></div> <div>{{info.Contact.Email}}</div></div>
                </div>
        </div>
    </div>
    
</template>

<script>
export default {
  created(){
    this.info=this.$store.state.info

  },
}
</script>