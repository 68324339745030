import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ContactView from '../views/ContactView.vue'
import HistoryView from '../views/HistoryView.vue'
import PortfolioView from '../views/PortfolioView.vue'
import test from '../views/test.vue'
Vue.use(VueRouter)

export const routes = [
  
  {
    path: '/',
    name: 'Home',
    component: Home,
    
  },
  {
    path: '/contact',
    name: 'ContactView',
    component: ContactView,
   
  },
  {
    path: '/background',
    name: 'HistoryView',
    component: HistoryView,
    
  },
  {
    path: '/portfolio',
    name: 'portfolioView',
    component: PortfolioView,
    
  },
  {
    path: '/test',
    name: 'test',
    component: test,
    
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
