<template>
<div :class="[activeOn ? 'bg-yellow':'bg-white','h-28']" class="bg-white">
<Button @click="activeOn = !activeOn" class="bg-white">button {{ activeOn }}</Button>

    <p :class="[activeOn ? 'transform -translate-x-4' : 'transform translate-x-full']" class="absolute right-0 transition duration-500 ease-in-out mt-5"> {{ text }} </p>
    <div v-if="activeOn" class="w-full bg-white  transform translate-x-0 transition duration-500">
        Hey divee
        
    </div>
    <div v-else class="transform -translate-x-full transition duration-500">
        Hey dive
    </div>
</div>
</template>

<script>
export default {
    data(){
        return {
            activeOn:true,
            text:'hello',
        }
    }
}
</script>