<template>
    <div  class="h-5/6 text-base font-bold">
        <div  class="flex justify-self-start p-2 bg-light-gray focus:outline-none w-full">
            
            <button @click="sidebarToggle()" class="p-4 focus:outline-none">
            <font-awesome-icon  icon="times" size="1x"/>
            </button>
        </div>

        <div  class="flex flex-col justify-center h-full px-8 overflow-hidden">
            <router-link v-for="(router,index) in routes" :key="index" :to="router.route">
                    <div :class="[router.flag && 'bg-yellow p-2 text-main-color']" class="flex p-2 space-x-2.5 mb-2 " @click="updateRoute(index)">
                  
                        <div class="w-6 flex justify-center self-center"> 
                            <font-awesome-icon  :icon="router.icon" size="1x"/>
                        </div>
                        <div>{{router.label}}</div>

                    </div>
            </router-link>
        </div>

    </div>

</template>

<script>

export default {
    data(){
        return {
            routes : this.$store.state.routes
        
        }
    },
    methods:{
        updateRoute(index){
            this.routes.map(router=>router.flag=false)
            this.$store.commit("updateRouteFlag", index);
        },
        sidebarToggle(){
            this.$root.$refs.appInstance.activateRightSidebar()
        }
    }
}
</script>