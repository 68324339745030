export function infoAPI() {
  return {
    'data': {

      'img': 'https://devfolio-rawheel.s3.amazonaws.com/images/raheel_pic.png',
      'name': 'Raheel Siddiqui',
      'bio': ['Full Stack Software Engineer','Leading tech Communities by AWS, GitHub and Google'],
      'Contact':
      {
        'Residence': 'Pakistan',
        'City': 'Karachi',
        'Age': '21',
        'Email': 'raheelsiddiqui365@gmail.com',
        'Phone': '+92 345 2764493'
      },
      'Experience': [
        { 'position': 'Integration Engineer', 'company': 'Cargobase', 'site': 'https://www.cargobase.com', 'location': 'Singapore,Remote', 'period': '09/2022 - Present', 'desc': 'Cargobase is a Software-as-a-Service (SaaS) global company having HQ in Singapore that aims to bridge the tech gap between shippers and logistics service providers. My role at Cargobase includes developing API integrations with logistic service providers and other parties and integrating them with backends by following modern and scalable code structural approaches and best programming practices eg. Test Driven Development(TDD).'},
        { 'position': 'Software Engineer', 'company': 'TradeHat', 'site': 'https://www.tradehat.com', 'location': 'USA,Remote(Part Time)', 'period': '04/2021 - 08/2022', 'desc': 'Tradehat is USA based startup focused on revolutionising financial quantative analysis & research space. My role at Tradehat includes mostly working with front-end team and writing automated testcases.' },
        { 'position': 'Associate Software Engineer', 'company': 'Sastaticket.pk', 'site': 'https://www.sastaticket.pk', 'location': 'Karachi, Pakistan', 'period': '07/2021 11/2021', 'desc': 'Sastaticket.pk, Pakistan’s largest online travel search and booking site, has been featured in Forbes Asia 100 to Watch – the annual list that highlighted notable startups on the rise across the Asia-Pacific region. My role at sastaticket.pk includes Full Stack development working with react and Django stack.' },
        
        { 'position': 'Research Intern', 'company': 'Micro Electronic Research Lab', 'site': 'https://www.merledupk.org', 'location': 'Karachi, Pakistan', 'period': '2019-2020', 'desc': 'MERL is an opensource organization, Focused on chip designing based on RISCV ISA. I researched & Developed single cyclic and five stage pipelined RISCV ISA based cores. Developed BURQ IDE to test the cores by contributing open source with Software Development team.' },
        { 'position': 'Python Developer', 'company': 'Freelance', 'site': 'https://www.fiverr.com/raheel_ali_', 'location': 'USA, France, Italy, Switzerland etc', 'period': '2020-2021', 'desc': 'Developed different solutions for individuals & helped multiple firms building their platforms.' },
        {'position':'AWS Community Builder - Data', 'company':"Amazon Web Services",'site':'https://aws.amazon.com/developer/community/community-builders/','location':'Karachi, Pakistan','period':'2021 - Present','desc':"The AWS Community Builders program offers technical resources, education, and networking opportunities to AWS technical enthusiasts and emerging thought leaders who are passionate about sharing knowledge and connecting with the technical community. I've been selected by AWS 2 years continuously as Community Builder in the Data category."},
        { 'position': 'GitHub Campus Expert', 'company': 'GitHub', 'site': 'https://education.github.com/experts', 'location': 'UIT Karachi', 'period': '10/2021 Present', 'desc': 'Campus Experts are student leaders that strive to build diverse and inclusive spaces to learn skills, share their experiences, and build projects together. They can be found across the globe leading in-person and online conferences, meetups, and hackathons, and maintaining open source projects.' },
        { 'position': 'Google Developer Student Club Lead', 'company': 'Google Developers', 'site': 'https://developers.google.com/community/gdsc', 'location': 'UIT Karachi', 'period': '2020-2021', 'desc': 'Lead Google Community at my campus. After getting training from Google Developers organized multiple Tech and Softskill based sessions by inviting industry experts. Contributed OpenSource and developed an extension for Google Platform for DSCs from over all the world. The extension is used by multiple Google DSC Leads from all over the world.' },

      ],
      'Education': {
        'University': { 'name': 'UIT University', 'status': 'Student', 'degree': 'Bachelor of Software Engineering', 'period': '2018 - 2022', 'desc': "Bachelor's Degree in Software Engineering from UIT-NED." },
        'College': { 'name': 'SSAT Degree College', 'status': 'Student', 'degree': 'FSC Pre Engineering', 'period': '2016 - 2018', 'desc': 'completed FSC part 1 and part 2 in Pre-Engineering from SSAT Degree College.' },
        'School': { 'name': 'Nasra Secondary School', 'status': 'Student', 'degree': 'SSC Computer Science', 'period': '2016', 'desc': 'Completed SSC part 1 and part 2 in Computer Science from Nasra Secondary School.' },

      },
      'Languages': {
        'Urdu': 90,
        'English': 90
      },
      'Skills': {
        'Backend Development': 85,
        'Frontend Development':70,
        'Data Analytics': 80,
        'Automation/Testing': 80,
        'Designing(UI/UX)': 75,
        'Creativity': 90,
        'Leadership': 90,
      },
      'Tools': ['Python','Javascript', 'Vue.js', 'Flask','FastAPI', 'Django', 'pandas', 'Numpy', 'Matplotlib', 'Selenium', 'BeautifulSoup4','Scrapy', 'chisel', 'CSS', 'HTML', 'Tailwindcss', 'Tkinter', 'PyQt5', 'photoshop', 'Illustrator', 'Adobe xd', 'Jira', 'Sql', 'Oracle', 'Git/GitHub'],
      'Resume': 'https://docs.google.com/document/d/1BAMzLdp9dHGznI_onSYY1ay0v1SPsld_ORfpVh6UgPE/export?format=pdf',
      'Socials': {
        'linkedin': 'https://www.linkedin.com/in/rawheel/',
        'github': 'https://github.com/rawheel',
        'twitter': 'https://twitter.com/rawheeel',
        'youtube': 'https://www.youtube.com/pytroops',
        'medium': 'https://rawheel.medium.com/',
        'linkedin_id': 'rawheel',
        'fiverr': 'https://fiverr.com/users/raheel_ali_/',
      },
      'Achievements': {
        'Completed Projects': '20+',
        'Freelance Clients': '40+',
        'Honors & Awards': '5+',
        'Opensource Projects': '5+',
      },
      'Experties': {
        'Full Stack Development':
          "I'm a passionate programmer with a particular affinity for Python, which I've used extensively to build solutions that tackle everyday challenges. I have experience working with a range of technologies and tools, including fastapi, Django, Vue, and JavaScript, and I strive to adhere to modern software development practices such as Test Driven Development. I also contribute passionately to open-source projects in my free time. I am always eager to learn and explore new approaches to problem-solving.",
        'Data Analytics':
          "I'm a data-driven person who thrives on the challenge of turning large amounts of information into actionable insights. I'm fascinated by the process of scraping, analyzing, and visualizing data to uncover patterns and trends. I've worked on various projects in this area and find it immensely rewarding when data-driven decisions lead to better outcomes. For me, the power of data lies in its ability to inform and inspire us to take action and drive positive change.",
        'Automation & Testing':
          "I've had the opportunity to work on several projects where I've gained experience writing automated test cases using a variety of tools such as Selenium, Pytest, snapshot testing, and regression testing. I enjoy automating my tasks and developing bots to streamline processes and improve efficiency. For me, the ability to automate repetitive tasks frees up time and energy to focus on more complex and creative problem-solving. I'm excited to continue exploring the possibilities of automation in my work.",
        'Creativity':
          "I'm a creative individual with experience using various Adobe tools for freelance projects and content creation on my YouTube channel. Through participating in hackathons, I've also gained proficiency with prototyping and UI/UX tools such as Adobe XD and Figma. In addition to my design work, I play the guitar and am passionate about music. Exploring music helps me to further develop my artistic skills and provides a refreshing outlet for my creativity.",
        'Community Builder':
          "As a hobby, I enjoy building communities and engaging with like-minded individuals to exchange ideas and learn from others' experiences. I have been involved in various tech communities such as Google Developer Student Club, Microsoft Student Ambassador, and Amazon Community Builder, where I have either led or participated as a member. Being part of such diverse environments and connecting with different people gives me a great sense of satisfaction and fulfillment.",
        'Open Source Contributor':
          "I'm committed to contributing to the community in my free time. During weekends, I dedicate some of my time to contribute to Open Source projects, which not only provides me an opportunity to learn from the best development practices but also allows me to contribute towards a greater cause. I have several Open Source projects listed on my GitHub, and I'm proud to share that some of them have been recommended and used by renowned programmers from around the world.",
      },
      'Recommendations': [
        {
          'name': 'Muhammad Hadir Khan',
          'profile': 'https://www.linkedin.com/in/muhammad-hadir-khan-771941162/',
          'img': 'https://devfolio-rawheel.s3.amazonaws.com/images/hadir.jpeg',
          'position': 'GSR MERL | Grad student at UC Santa Cruz',
          'recommendation': 'Raheel is a great student who is an enthusiastic learner. He is always ready to learn new skills and is a very hard worker. His leadership skills are maturing and he is in the right direction for success.'
        },

        {
          'name': 'Ayesha Saeed',
          'profile': 'https://www.linkedin.com/in/ayesha-saeed-756324147/',
          'img': 'https://devfolio-rawheel.s3.amazonaws.com/images/ayesha.jpeg',
          'position': 'Wikipedia Specialist/Writer at Innovative Network Pvt Ltd',
          'recommendation': 'Raheel is one of the most hardworking juniors I have come across. He knows how to come up with new ideas and implement them with ease. His vision is crystal clear to him which is something required for a successful future.'

        },
        {
          'name': 'Muhammad Kumail',
          'profile': 'https://www.linkedin.com/in/mkumail1/',
          'img': 'https://devfolio-rawheel.s3.amazonaws.com/images/kumail.jpeg',
          'position': 'Blockchain Developer | MERN Stack | β Microsoft LSA | Former Google DSC Lead | AWS Cloud Ambassador | Global Leader at UNAI',
          'recommendation': 'Raheel is a really talented individual who has a keen desire for solving complex problems. I worked over several events with him in DSC and he is certainly one of the most dedicated personality I ever came across with. I wish him the best of luck for his future endeavours.',
        },


      ],
      'Reviews': [
        {
          'name': 'nathanwillem',
          'site': 'Fiverr',
          'location': 'Netherlands',
          'ratings': 5,
          'review': 'It is always nice to work with him. The work is finished quickly and he does more than is asked of him. It is also clear that he has a lot of knowledge of the work he does. I look forward to our next collaboration.'
        },
        {
          'name': 'stevenfisher512',
          'site': 'Fiverr',
          'location': 'United Kingdom',
          'ratings': 5,
          'review': 'Needed a script to be developed with which involves PDFs. This is very hard for most developers to complete. Had a bug and he was quick to find a solution and deliver exactly what I wanted. Received the script within 3 days and will be using again for future projects.'
        },
        {
          'name': 'jonaseko',
          'site': 'Fiverr',
          'location': 'Belgium',
          'ratings': 5,
          'review': 'Fantastic experience, Raheel was very helpful and thought along. Very smooth communication and great end product!'
        },
        {
          'name': 'thealika',
          'site': 'Fiverr',
          'location': 'Italy',
          'ratings': 5,
          'review': 'Great Seller, made an amazing effort to make sure everything is 100% perfect! Very happy with the results and his dedication to finishing the Python Projects!! Highly recommended!'
        },
        {
          'name': 'eyebrowfleeky',
          'site': 'Fiverr',
          'location': 'United Kingdom',
          'ratings': 5,
          'review': 'Raheel completed my project straight away. He was very fast and knew what he was doing. He even stayed up late at night due to time difference so we could zoom as he showed me how to work the program. 2nd time working with him. Highly recommend!'
        },
        {
          'name': 'calvinwhite552',
          'site': 'Fiverr',
          'location': 'New Zealand',
          'ratings': 5,
          'review': 'He is a friendly and fast worker. Got my project done extremely fast and up to a high standard and exactly what I was looking for. If you are looking for someone to do python projects for you then this is your guy. 100% recommend and would 100% order again.'
        },

      ],
      'Portfolio': [
        {
          'name': 'FastAPI BoilerPlate',
          'desc': 'Dockerized FastAPI boiler plate similar to Django code structure with views, serializers(pydantic) and model( Sqlalchemy ORM) with dockerized database(PostgresSQL) and PgAdmin. 🚀',
          'img': 'https://raw.githubusercontent.com/rawheel/fastapi-boilerplate/main/media/fastapi%20boilerplate%20cover.png',
          'url': 'https://github.com/rawheel/fastapi-boilerplate',
          'tools': ['docker-compose', 'fastapi', 'python3', 'postgresql', 'sqlalchemy']
        },
        {
          'name': 'My Unfollowers App',
          'desc': 'An app that tells who unfollowed you on github..',
          'img': 'https://github.com/rawheel/My-Unfollowers-Vue-App/raw/main/screenshots/cover.jpg',
          'url': 'https://github.com/rawheel/MyUnfollowers-App',
          'tools': ['python', 'flask', 'vue.js']
        },
        {
          'name': 'Event Registration App',
          'desc': 'CRUD operations on registrations App.',
          'img': 'https://github.com/rawheel/Events-Registration-App/blob/api-int/src/assets/cover.jpg?raw=true',
          'url': 'https://github.com/rawheel/Events-Registration-App/',
          'tools': ['python', 'django', 'vue.js']
        },
        {
          'name': 'Tv Show Listing Desktop App',
          'desc': 'A simple GUI app to manage tv shows data. 📷',
          'img': 'https://github.com/rawheel/Tvshows-Listing-Desktop-App/raw/main/screenshots/ss2.png?raw=true',
          'url': 'https://github.com/rawheel/Tvshows-Listing-Desktop-App',
          'tools': ['python', 'pyqt5', 'qtdesigner']
        },
        {
          'name': 'Google DSC Platform Extension',
          'desc': 'Automate the process of adding attendees manually.',
          'img': 'https://github.com/DSC-UIT-khi/Google-DSC-Platform-Extension/raw/master/screenshots/main_window.jpg?raw=true',
          'url': 'https://github.com/DSC-UIT-khi/Google-DSC-Platform-Extension',
          'tools': ['python', 'bash', 'pyqt5', 'selenium', 'pandas']
        }
      ]


    }
  }
}
