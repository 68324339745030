<template>
    <div id="chart" v-if="score">
        <apexchart type="radialBar" height="110"  :options="chartOptions" :series="seriesVal"></apexchart>
    </div>
</template>

<script>
export default {
    props: {
    score:{
     type:   Number,
     default: 100
    } 
  },
    data: function() {
      return {
          seriesVal: [0],

      }
    },
    computed:{
       
        chartOptions(){
            return {
        
            
          
            chart: {
               
              type: 'radialBar',
            },
            labels: [this.score],
            plotOptions: {
               
              radialBar: {
                hollow: {
               
                size: '50%',
                  
                },
                track:{
                    show:true,
                    background: '#161A23',
                    
                },
                dataLabels:{
                show:false,
                name:{
                    
                    show:true,
                    color:'#fff',
                    fontSize:"10px",
                    offsetY: 5,
                    formatter: function (val) {
                        return `${val}%`;
                    },


                },
                value: {
                show:false,
                color: "#161A23",
                fontSize: "9px",
                
                formatter: function (val) {
                  return `${val}%`;
                },

                }

            },
            
              },
            },
            
            colors:['#FFC107'], 
          
      }
        }
    },
    created(){
        this.seriesVal= [this.score]
    },
};

</script>