<template>
    <div>
        <div class="grid justify items-center grid-flow-row md:grid-cols-2 grid-rows-auto gap-4">
            <div v-for="(proj,index) in info.Portfolio" :key="index" class="h-full w-full " > 
            <div  class="bg-light-gray overflow-hidden h-full" >
                <img :src=proj.img alt="portfolio img" class="w-full object-cover h-32 sm:h-48 md:h-64">
                <div >
                    <div id='arrow' class="py-2 px-6 bg-light-gray">
                    <div class="flex justify-between p-0 m-0 ">
                        <h3 class="mr-2 font-semibold pt-2 text-2xl text-white leading-tight sm:leading-normal"><a :href=proj.url target="_blank">{{proj.name}}</a></h3>
                        <div class="text-white transition duration-500 hover:text-yellow transform hover:-translate-y-1 hover:scale-110 pt-4 text-base"><a :href=proj.url target="_blank"><font-awesome-icon icon="globe" /></a></div>

                    </div>
                        <p class="text-xs text-gray-500 font-normal">{{proj.desc}}</p>
                        <div class="text-sm flex py-2">
                            <div v-for="(tool,index) in proj.tools" :key="index" class=" bg-dark-yellow  mx-1 my-1 p-2 text-xs rounded-3xl text-white font-light">
                                {{tool}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>

            
        </div>
        <div class="mt-4 ">
                <div class="flex justify-between text-xs font-normal text-gray-500 py-4 px-2 md:px-4 w-full bg-gray">
                    <div><font-awesome-icon icon="copyright" transform="shrink-2"/> 2022 All Rights Reserved.</div>
                    <div class="hidden md:flex">Phone: {{info.Contact.Phone}}</div>
                    <div class="flex"><div class="mr-1"><font-awesome-icon  icon="envelope" :style="{height:'12px',width:'12px' }"/></div> <div>{{info.Contact.Email}}</div></div>
                </div>
        </div>
    </div>
    
</template>

<script>
export default {
    created(){
        this.info = this.$store.state.info
    }
}
</script>
<style>
#arrow {
  position: relative;
  top: 0;
  transition: top ease 0.5s;
}
#arrow:hover {
  top: -30px;
}


</style>