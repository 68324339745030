<template>
  <div class="absolute overflow-x-hidden bottom-0 top:0 left:0 right:0 margin:0  bg-main-color h-full w-full">
      
      <div id="main-wrapper" class="realtive bg-main-color overflow-x-hidden lg:flex lg:justify-between font-sans lg:m-4 h-full">
        <div @click="deactivateSidebars()" v-if="this.$store.state.isActiveLeftSidebar || this.$store.state.isActiveRightSidebar" class="lg:-mx-20 absolute overflow-hidden bg-black opacity-30 z-30  h-full w-full">
      </div>
        
      <div class="z-20 sticky top-0 bg-main-color text-gray-500 h-18 flex justify-between lg:hidden">

        <button  @click="activateLeftSidebar()" class=" p-4 focus:outline-none">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
          </svg>
        </button>

        <button @click="activateRightSidebar()" class="p-4 focus:outline-none">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>

      </div>

      <div id="left-sidebar" :class="[this.$store.state.isActiveLeftSidebar ? 'transform translate-x-0 z-30' : 'transform -translate-x-full']" class="overflow-x-hidden z-20  bg-gray text-white w-64 text-xs absolute inset-y-0 left-0
      transition duration-300 ease-in-out lg:relative lg:translate-x-0 filter drop-shadow-3xl">
        <SideBar/>
      </div>

    <div class=" overflow-hidden h-full lg:w-9/12">
      
    <router-view/>
    </div> 

      <div id="extended-sidebar" :class="[this.$store.state.isActiveRightSidebar ? 'lg:m-4 transform translate-x-0 ' : ' transform translate-x-full ']" class="z-40 lg:my-4 bg-gray text-gray-500 w-64 text-xs absolute inset-y-0 right-0
        transition duration-300 ease-in-out ">
            <ExtendedSidebar/>
      </div>

        <div id="right-sidebar" :class="[this.$store.state.isActiveRightSidebar ? ' ' : ' transform lg:translate-x-0 translate-x-full ']" class=" bg-gray text-gray-500 w-20 text-xs absolute inset-y-0 right-0
            transition duration-100 lg:relative">
          <RightSidebar/>
        </div>
      </div>
      </div>



</template>

<script>

import SideBar from '@/components/SideBar.vue'
import RightSidebar from '@/components/RightSidebar.vue'
import ExtendedSidebar from '@/components/ExtendedSidebar.vue'
import {infoAPI} from '@/services/api-services/info'

export default {
  name: 'App',
  components: {
    SideBar,
    RightSidebar,
    ExtendedSidebar
    // v-if="this.$store.state.isActiveLeftSidebar || this.$store.state.isActiveRightSidebar" 
  },
  
  data(){
    return {
      info:{},
      coverImg: require('@/assets/cover.png'),
    }
  },
  created(){
    this.getData()
    this.storeDataInStore()
    this.info=this.$store.state.info
    this.$root.$refs.appInstance = this;
    this.deactivateSidebars()
    

  },

  methods:{
    storeDataInStore(){
      this.$store.commit("updateInfo", this.info);
    },
    // USE THIS METHOD WHEN YOU CONNECT BACKEND API
      // getData(){
      //   this.$api.info.infoAPI().then(response => {
      //   this.info = response.data;
      // });
      // },
    getData(){
      this.info=infoAPI().data
    },
    activateLeftSidebar: function(){
      this.$store.commit("updateisActiveRightSidebar", false);
      this.$store.commit("updateisActiveLeftSidebar", !this.$store.state.isActiveLeftSidebar);
    },
    activateRightSidebar: function(){
      this.$store.commit("updateisActiveLeftSidebar", false);
      this.$store.commit("updateisActiveRightSidebar", !this.$store.state.isActiveRightSidebar);
    },
    deactivateSidebars: function(){
      this.$store.commit("updateisActiveRightSidebar", false);
      this.$store.commit("updateisActiveLeftSidebar", false);
    },
  }
}
</script>

<style>
@media(min-width:1000px){
  #main-wrapper{
    height: 95%;
    }
}

#left-sidebar,#extended-sidebar,#main-wrapper {
 
  overflow-y: scroll; /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
#left-sidebar::-webkit-scrollbar, #extended-sidebar::-webkit-scrollbar,#main-wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#left-sidebar,#extended-sidebar,#main-wrapper{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>