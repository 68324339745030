import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


import VueApexCharts from 'vue-apexcharts'
import ProgressBar from 'vuejs-progress-bar'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCopyright,faMapMarkerAlt,faStar,faTimes,faUser,faEnvelope,faGlobe,faHome,faHandshake,faUserGraduate,faLaptopCode} from '@fortawesome/free-solid-svg-icons'
import { faLinkedin,faGithub,faTwitter,faYoutube,faMedium } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

import Typewriter from 'vue-typewriter'

import Hotjar from 'vue-hotjar'

Vue.use (Hotjar, {
  id: '2644565',
  isProduction: true,
})

library.add(faLinkedin,faGithub,faTwitter,faMedium,faYoutube,faCopyright,faMapMarkerAlt,faStar,faUser,faEnvelope,faGlobe,faTimes,faHome,faHandshake,faUserGraduate,faLaptopCode)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(ProgressBar)
Vue.use(Typewriter);

Vue.use(VueApexCharts)

const options = {
  confirmButtonColor: '#FFC107',
  background: '#161A23',
  

};
Vue.use(VueSweetalert2,options);

Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false

// USES THIS WHEN API IS CONNECTED
import * as services from "./services";
Vue.prototype.$api = services.default;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
