<template>
    <div id="chart" v-if="barValue">
    <progress-bar
     
    :options="options"
    :value="value"
    />
    </div>
</template>

<script>
export default {
    props:{
        barValue:{
         type:Number,
         
        }    
    },
    data(){
        return {
            value : 0
            
        }
    },
    computed:{
        

        options(){
            return {
                text: {
                color: '#FFFFFF',
                shadowEnable: true,
                shadowColor: '#000000',
                fontSize: 14,
                fontFamily: 'Helvetica',
                dynamicPosition: false,
                hideText: true
            },
            progress: {
                color: '#FFC107',
                backgroundColor: '#333333',
                inverted: false
            },
            layout: {
                height: 5,
                width:220,
               
               
                verticalTextAlign: 61,
                horizontalTextAlign: 43,
                zeroOffset: 0,
                strokeWidth: 30,
                progressPadding: 0,
                type: 'line'
            }
            }
        }
    },
    created(){
        this.value= this.barValue
    }
}
</script>